/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import useStyles from '../styles/landing/style';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  Container,
  Box
  // Divider
} from '@material-ui/core';
import clsx from 'clsx';
import Layout from '../layouts/Main';
import { useAuthentication } from '../hooks/useAuthentication';
import Router from 'next/router';
import LazyLoad from 'react-lazyload';
import { sibiEmmaBase64Img, templatesBase64Img } from '../images/landingBase64';
import ReviewsSection from '../components/ReviewsSection';
import VideoPlayerRaw from '../components/VideoPlayerRaw';
import Link from 'next/link';

const Landing = () => {
  const classes = useStyles();
  const layoutRef = React.useRef(null);

  const { user } = useAuthentication();

  React.useEffect(() => {
    if (user) Router.push('/dashboard', undefined, { shallow: true });
  }, [user]);

  return (
    <Layout
      forwardRef={layoutRef}
      showTutorials={false}
      showLanguageMenu={false}
      showNotificationPanel={false}
      audience="video"
    >
      <Box className={classes.section}>
        {/* --- Section Header --- */}
        <Box className={classes.mainSection}>
          <Container style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={clsx(classes.textHeader, 'ta-center', 'mb-0')}>
              <span>{'Make Videos & Social Content.'}</span>
              <span className={classes.textGradient}>{' Faster'}</span>
            </Typography>
          </Container>
          <Container style={{ maxWidth: 1024 }}>
            <Box className={clsx(classes.textSlogan, 'ta-center', classes.hide_xs)}>
              <span>Are you spending too much time creating video and social content?</span>
            </Box>
            <Box className={clsx(classes.textSlogan, 'mt-5', 'ta-center', classes.hide_xs)}>
              <span>Don’t go anywhere, WOXO is about to give you back time!</span>
              <span></span>
            </Box>
            <Box className={clsx(classes.textSlogan, 'ta-center', classes.show_xs)}>
              <span>Are you spending too much time creating video and social content?</span>
              <span> Don’t go anywhere, WOXO is about to give you back time!</span>
            </Box>
            <Box className={classes.panelImageContainer}>
              <VideoPlayerRaw
                lazyLoad={false}
                videoPreviewImage={sibiEmmaBase64Img}
                url={
                  'https://res.cloudinary.com/dakp804eh/video/upload/v1625694629/woxo-landing-page/Benefits_of_Woxo_videos_2.mp4'
                }
              />
            </Box>
            <Box className={classes.buttonContainer}>
              <Button
                className={classes.button}
                data-name="wt-header-btn"
                data-type="btn-click"
                href="/video/templates"
              >
                Get started
              </Button>
            </Box>
          </Container>
        </Box>
        {/* --- Section Header --- */}
        {/* // --- Section How It Works --- */}
        <Box className={classes.secondaryBg}>
          <Container className={classes.secondarySection} style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={classes.title}>
              How It Works
            </Typography>
            <Box className={clsx(classes.container, 'cont-2-1')}>
              <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                <VideoPlayerRaw
                  defaultHeight={300}
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://res.cloudinary.com/dakp804eh/video/upload/v1625695127/woxo-landing-page/How_It_Works.mp4'
                  }
                />
              </Box>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan, 't-20')}>
                  <p>With WOXO, you can bulk create content and put it out every single day.</p>
                  <p>
                    Just check out how easy it is to choose from WOXO’s templates, edit the video to
                    your preferences using your assets from webpages or social media to give them a
                    new life, and boom!
                  </p>
                  <p>You’re all set.</p>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* // --- Section How It Works --- */}
        {/* --- Section 3: Meet Woxo Video Maker --- */}
        <Box className={classes.secondarySection}>
          <Box className={classes.removeStyles}>
            <Container style={{ maxWidth: 659 }}>
              <Typography variant="h2" className={classes.title}>
                Ready-to-go Templates
              </Typography>
              <Box className={classes.textSlogan}>
                <p>
                  Browse through a growing catalog of templates made by a vibrant video content
                  creator's community.
                </p>
              </Box>
            </Container>
            <Container>
              <Box className={classes.panelImageContainer}>
                <VideoPlayerRaw
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://res.cloudinary.com/dakp804eh/video/upload/v1625695346/woxo-landing-page/Templates_1.mp4'
                  }
                />
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-section-3-btn"
                  data-type="btn-click"
                  href="/video/templates"
                >
                  Browse all templates
                </Button>
              </Box>
            </Container>
          </Box>
        </Box>
        {/* --- Section 3: Meet Woxo Video Maker --- */}
        {/* // --- Section Custom Branding and Calendar Ideas --- */}
        <Box className={clsx(classes.secondaryBg, classes.secondarySection)}>
          <Container style={{ maxWidth: 659 }}>
            <Typography variant="h1" className={classes.title}>
              Custom Branding and Calendar Ideas
            </Typography>
          </Container>
          <Container style={{ maxWidth: 1024 }}>
            <Box className={clsx(classes.container, 'cont-1-2')}>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan, 't-20')}>
                  <p>You can get inspired with fresh ideas for your content calendar.</p>
                  <p>
                    Apply your own branding with easy customization features, and even advanced ones
                    if you want.
                  </p>
                </Box>
              </Box>
              <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                <VideoPlayerRaw
                  defaultHeight={300}
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://res.cloudinary.com/dakp804eh/video/upload/v1625695417/woxo-landing-page/Calendar_and_Branding_Screen_1280x720_1.mp4'
                  }
                />
              </Box>
            </Box>
          </Container>
        </Box>
        {/* // --- Section Custom Branding and Calendar Ideas --- */}
        {/* --- Section Easy Team Collaboration --- */}
        <Box className={classes.secondarySection}>
          <Box className={classes.removeStyles}>
            <Container style={{ maxWidth: 659 }}>
              <Typography variant="h2" className={classes.title}>
                Easy Team Collaboration
              </Typography>
              <Box className={classes.textSlogan}>
                <p>
                  WOXO lets your team{' '}
                  <strong>
                    collaborate to edit and create videos simultaneously in the spreadsheet
                  </strong>
                  , transforming them into instant dynamic videos at scale in minutes.
                </p>
              </Box>
            </Container>
            <Container>
              <Box className={classes.panelImageContainer}>
                <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                  <VideoPlayerRaw
                    loop={true}
                    videoPreviewImage={templatesBase64Img}
                    url={
                      'https://res.cloudinary.com/dakp804eh/video/upload/v1625698112/woxo-landing-page/Team%20Collaboration.mp4'
                    }
                  />
                </Box>
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-section-3-btn"
                  data-type="btn-click"
                  href="/video/templates"
                >
                  Get started
                </Button>
              </Box>
            </Container>
          </Box>
        </Box>
        {/* --- Section Easy Team Collaboration --- */}
        {/* // --- Section Personalization at Scale --- */}
        <Box className={classes.secondaryBg}>
          <Container className={classes.secondarySection} style={{ maxWidth: 1024 }}>
            <Typography variant="h1" className={classes.title}>
              Personalization at Scale
            </Typography>
            <Box className={clsx(classes.container, 'cont-2-1')}>
              <Box className={clsx(classes.mainSectionImgBox, classes.order_1_xs)}>
                <VideoPlayerRaw
                  defaultHeight={300}
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://res.cloudinary.com/dakp804eh/video/upload/v1625695501/woxo-landing-page/Personalization_at_Scale_1.mp4'
                  }
                />
              </Box>
              <Box className={clsx(classes.order_2_xs)}>
                <Box className={clsx(classes.textSlogan, 't-15')}>
                  <p>Here’s the coolest thing about WOXO.</p>
                  <p>
                    The bulk sheet features allow you to make as many custom videos as you want for
                    emailing marketing and personalized landing pages.
                  </p>
                  <p>This will give your brand a huge advantage with personalized content! 💃🏼🕺🏿</p>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        {/* // --- Section Personalization at Scale --- */}
        {/* --- Section Social Content Widgets --- */}
        <Box className={classes.secondarySection}>
          <Box className={classes.removeStyles}>
            <Container style={{ maxWidth: 659 }}>
              <Typography variant="h2" className={classes.title}>
                Social Content Widgets
              </Typography>
              <Box className={classes.textSlogan}>
                <p>
                  WOXO doesn’t just make it easy to create video content at scale. It also offers
                  widgets that allow you to share your social media content directly on your
                  website.
                </p>
              </Box>
            </Container>
            <Container>
              <Box className={classes.panelImageContainer}>
                <VideoPlayerRaw
                  loop={true}
                  videoPreviewImage={templatesBase64Img}
                  url={
                    'https://res.cloudinary.com/dakp804eh/video/upload/v1625695593/woxo-landing-page/Widgets_1280x720.mp4'
                  }
                />
              </Box>
              <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-section-3-btn"
                  data-type="btn-click"
                  href="/instagram-widget"
                >
                  Create widget
                </Button>
              </Box>
            </Container>
          </Box>
        </Box>
        {/* --- Section Social Content Widgets --- */}
        {/* // --- Section 5: Our Woxians say --- */}
        {/* <OurWoxiansSay /> */}
        <ReviewsSection />
        {/* // --- Section 5: Our Woxians say --- */}
        {/* --- Section 1: Companies --- */}
        <div className={classes.marqueeContainer}>
          <Box display="flex" justifyContent="center">
            <Typography variant="p" className={classes.marqueeText}>
              Proud to help companies, small businesses, influencers, and entrepreneurs across 147
              countries.
            </Typography>
          </Box>
          <Container>
            <div className={classes.marquee}>
              {/* <div className={classes.transparenceCover} /> */}
              <LazyLoad height={300}>
                <div item className={classes.marqueeDiv}>
                  <img
                    alt="apinchofkinder"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067103/videoLandingPage/new/1.png"
                  />
                  <img
                    alt="szk"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/8.png"
                  />
                  <img
                    alt="bissu"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/7.png"
                  />
                  <img
                    alt="hollagugu"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/2.png"
                  />
                  <img
                    alt="snapstore"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/3.png"
                  />
                  <img
                    alt="rytiri"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/4.png"
                  />
                  <img
                    alt="broadway"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/6.png"
                  />
                  <img
                    alt="rev"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/10.png"
                  />
                  <img
                    alt="citilink"
                    className={classes.marqueeImg}
                    src="https://res.cloudinary.com/dakp804eh/image/upload/v1615067104/videoLandingPage/new/9.png"
                  />
                </div>
              </LazyLoad>
            </div>
          </Container>
        </div>
        {/* --- Section 1: Companies --- */}
        {/* // --- Section 7: Call to Action --- */}
        <Container className={classes.secondarySection}>
          <div className={classes.ctaContainer}>
            <LazyLoad height={300} offset={100}>
              <div className={clsx(classes.ctaContainer__fig_box)}>
                <img
                  alt="fig1"
                  src="https://res.cloudinary.com/dakp804eh/image/upload/v1615051496/videoLandingPage/vg-header-fig-1.svg"
                  className={classes.ctaContainer__fig_box__fig_1}
                />
                <img
                  alt="fig2"
                  src="https://res.cloudinary.com/dakp804eh/image/upload/v1621573279/woxo-landing-page/f2.svg"
                  className={classes.ctaContainer__fig_box__fig_2}
                />
              </div>
            </LazyLoad>
            <div className={classes.ctaInfo}>
              <Typography variant="h2" className={classes.subTitle}>
                Start Creating Videos Today
              </Typography>
              <Typography variant="h1" className={classes.title}>
                <span className={classes.textGradient}>
                  Upgrade for as little as $22.50 a month
                </span>
              </Typography>
              <Box className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  data-name="wt-try-it-for-yourself-btn"
                  data-type="btn-click"
                  href="/video/templates"
                >
                  Try it free
                </Button>
              </Box>
              <Link href="/plans">
                <a
                  className={classes.ctaLink}
                  data-name="wt-see-all-plans-btn"
                  data-type="btn-click"
                >
                  View all plans
                </a>
              </Link>
            </div>
          </div>
        </Container>
        {/* // --- Section 7: Call to Action --- */}
      </Box>
    </Layout>
  );
};

export default Landing;
